import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import moment from "moment-timezone";
import EventModule from "@/store/module/shared/EventModule";
import HandbookModule from "@/store/module/shared/HandbookModule";
import UsersTable from "@/components/admin/users/UsersTable.vue";
import UserModule from "@/store/module/user/UserModule";
import ApproveDialog from "@/components/shared/Approve.vue";
import EventDialog from "@/components/shared/dialogs/Event.vue";
import EventParticipants from "@/components/user/events/EventParticipants.vue";
import EventOwnParticipation from "@/components/user/events/EventOwnParticipation.vue";
let EventPage = class EventPage extends Vue {
    constructor() {
        super(...arguments);
        this.event = null;
        this.dialog = {
            updateEvent: false,
            declareParticipants: false,
            recallParticipants: false,
            fillProfile: false,
            declareOwnParticipation: false,
            recallOwnParticipation: false,
        };
        this.participantsHeaders = [
            {
                text: "Амплуа",
                sortable: true,
                value: "ampluaView",
            },
            {
                text: "Дисциплина",
                sortable: true,
                value: "disciplineView",
            },
            {
                text: "Категория",
                sortable: true,
                value: "categoryView",
            },
            {
                text: "Фамилия",
                sortable: true,
                value: "surname",
            },
            {
                text: "Имя",
                sortable: true,
                value: "name",
            },
            {
                text: "Отчество",
                sortable: true,
                value: "patronymic",
                align: "center",
            },
        ];
        this.amplua = {
            ref: "amplua",
            type: "select",
            label: "Амплуа",
            value: null,
            items: () => this.ampluaHandbook,
            onInput: () => {
                if (this.amplua.value === 1) {
                    this.category.disabled = false;
                }
                else {
                    this.category.disabled = true;
                }
            },
            showOnlyForSuperAuth: false,
            rules: [],
            show: true,
        };
        this.category = {
            ref: "category",
            type: "select",
            label: "Категория",
            value: null,
            items: () => this.eventCategories,
            onInput: () => { },
            showOnlyForSuperAuth: false,
            rules: [],
            show: true,
            disabled: true,
        };
        this.filters = [this.amplua, this.category];
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    get isCoach() {
        return this.$store.getters[UserModule.types.getters.iS_COACH];
    }
    get avatar() {
        if (!this.event) {
            return `${IMAGE_SERVER_URL}/public/archery-event-placeholder.jpg`;
        }
        return `${IMAGE_SERVER_URL}/public/${this.event.logo}`;
    }
    get description() {
        return this.event && this.event.description ? this.event.description : "Отсутсвует";
    }
    get canEdit() {
        if (this.isCoach && this.event?.userCreatorId === this.user.id)
            return true;
        return false;
    }
    get isParticipant() {
        if (!this.user || !this.event)
            return false;
        return !!this.event.participants.find((p) => p.userId === this.user.id);
    }
    get canDeclareParticipants() {
        if (moment(this.event.registrationEndDate).isBefore(moment()))
            return false;
        if (this.event.isPrivate && (this.event.type.regionalStatusId === 1 || this.event.type.regionalStatusId === 2)) {
            return false;
        }
        return this.isCoach;
    }
    get canDeclareOwn() {
        if (moment(this.event.registrationEndDate).isBefore(moment()))
            return false;
        if (this.event.isPrivate)
            return false;
        return true;
    }
    get canRecallOwn() {
        if (this.isParticipant)
            return true;
        return false;
    }
    get participants() {
        let participants = this.event.participants.filter((participant) => {
            if (!participant.user)
                return false;
            if (participant.user.isPremoderated)
                return false;
            if (this.amplua.value && participant.ampluaId !== this.amplua.value)
                return false;
            if (this.category.value && participant.categoryId !== this.category.value)
                return false;
            if (this.user) {
                return participant.user.address !== null && participant.user.address.regionId === this.user.address.regionId;
            }
            return true;
        });
        const items = {
            participants: participants.map((participant, index) => {
                let disciplineView = "";
                if (participant.discipline && participant.discipline.discipline) {
                    disciplineView = `${participant.discipline.disciplineGroup.name} | ${participant.discipline.discipline.name}`;
                }
                else if (participant.discipline && participant.discipline.disciplineGroup) {
                    disciplineView = participant.discipline.disciplineGroup.name;
                }
                return {
                    ...participant.user,
                    ampluaView: participant.amplua.name,
                    categoryView: participant.category && participant.category.category
                        ? `${participant.category.category.name} от ${participant.category.category.ageCategory.ageFrom} до ${participant.category.category.ageCategory.ageTo} лет`
                        : "",
                    disciplineView,
                    participant,
                };
            }),
        };
        return items;
    }
    get disciplinesTree() {
        const tree = [];
        if (this.event === null || this.event === undefined)
            return [];
        this.event.disciplines.map((item) => {
            let lastTreeItemIndex = -1;
            const parent = tree.find((fItem) => fItem.id === item.disciplineGroupId);
            if (parent === undefined) {
                lastTreeItemIndex =
                    tree.push({
                        ...item.disciplineGroup,
                        children: [],
                    }) - 1;
                if (item.discipline !== null) {
                    tree[lastTreeItemIndex].children.push(item.discipline);
                }
            }
            else if (item.discipline !== null) {
                parent.children.push(item.discipline);
            }
        });
        return tree;
    }
    get categoriesNames() {
        if (this.event === undefined || this.event === null)
            return [];
        return this.event.categories.map((item) => `${item.category.name} от ${item.category.ageCategory.ageFrom} до ${item.category.ageCategory.ageTo} лет`);
    }
    get ampluaHandbook() {
        return [...this.$store.getters[HandbookModule.types.getters.AMPLUA]].reverse();
    }
    get eventCategories() {
        if (!this.event)
            return [];
        return this.event.categories.map((item) => {
            return {
                ...item.category,
                name: `${item.category.name} от ${item.category.ageCategory.ageFrom} до ${item.category.ageCategory.ageTo} лет`,
                id: item.id,
            };
        });
    }
    get filtersValues() {
        return {
            ampluaId: this.amplua.value || null,
            categoryId: this.category.value || null,
        };
    }
    get profile() {
        if (!this.event)
            return {
                main: [],
                additional: [],
                sport: [],
            };
        const main = [
            { title: "Название", value: this.event.name },
            { title: "Тип", value: this.event.type.name },
            {
                title: "Начало",
                value: moment(this.event.startDate).tz("Europe/Moscow").format("YYYY-MM-DD"),
            },
            {
                title: "Конец",
                value: moment(this.event.endDate).tz("Europe/Moscow").format("YYYY-MM-DD"),
            },
            {
                title: "Конец регистрации",
                value: moment(this.event.registrationEndDate).tz("Europe/Moscow").format("YYYY-MM-DD"),
            },
            { title: "Регистрация открыта", value: this.event.isOpenForRegistration === true ? "Да" : "Нет" },
            { title: "Тип события", value: this.event.isPrivate ? "Закрытое" : "Открытое" },
            { title: "Наличие взноса", value: this.event.participationFee === null ? "Нет" : "Есть" },
        ];
        if (this.event.participationFee !== null) {
            main.push({
                title: "Сумма взноса",
                value: this.event.participationFee + " руб.",
            });
        }
        return {
            main,
            additional: [
                {
                    title: "Федеральный округ",
                    value: this.event.address === null ? "Не указан" : this.event.address.region.district.name,
                    isTree: false,
                },
                {
                    title: "Регион",
                    value: this.event.address === null ? "Не указан" : this.event.address.region.name,
                    isTree: false,
                },
                {
                    title: "Город",
                    value: this.event.address !== null && this.event.address.city !== null
                        ? this.event.address.city.name
                        : "Не указан",
                    isTree: false,
                },
            ],
            sport: [
                { title: "Категории", value: "<br><br>" + this.categoriesNames.join("<br>"), isTree: false },
                { title: "Дисциплины", value: this.disciplinesTree, isTree: true },
            ],
        };
    }
    get limits() {
        if (!this.event)
            return [];
        const limitKey = (categoryId, disciplineId) => `${categoryId}-${disciplineId}`;
        const participantsCountTable = new Map();
        for (const participant of this.event.participants) {
            if (participant.user.address.regionId !== this.user.address.regionId) {
                continue;
            }
            const key = limitKey(participant.categoryId, participant.disciplineId);
            if (!participantsCountTable.has(key)) {
                participantsCountTable.set(key, 1);
            }
            else {
                const currentCount = participantsCountTable.get(key);
                participantsCountTable.set(key, currentCount + 1);
            }
        }
        const limitsTable = new Map();
        const items = [];
        this.event.limits.map((l) => limitsTable.set(limitKey(l.eventCategoryId, l.eventDisciplineId), l.limit));
        for (const category of this.event.categories) {
            for (const discipline of this.event.disciplines) {
                const key = limitKey(category.id, discipline.id);
                const limit = limitsTable.get(key);
                const participantsCount = participantsCountTable.get(key) === undefined ? 0 : participantsCountTable.get(key);
                const disciplineName = discipline?.discipline?.name
                    ? discipline.discipline.name
                    : discipline.disciplineGroup.name;
                const currentlyParticipated = participantsCount > limit ? limit : participantsCount;
                const reserve = participantsCount - limit;
                const reserveView = reserve > 0 ? ` <span class="teal--text accent-2">(резерв ${reserve})</span>` : "";
                const limitView = limit === this.$store.getters[EventModule.types.getters.UNLIMITED_PARTICIPANTS_VALUE]
                    ? ""
                    : ` из ${limit}${reserveView}`;
                items.push({
                    title: `${disciplineName} - ${category.category.name} от ${category.category.ageCategory.ageFrom} до ${category.category.ageCategory.ageTo}: `,
                    value: `${currentlyParticipated}${limitView}`,
                });
            }
        }
        return items;
    }
    async onEventUpdate() {
        this.event = await this.$store.dispatch(EventModule.types.actions.LOAD_EVENT, { id: this.event.id });
    }
    async recallParticipation() {
        await this.$store.dispatch(EventModule.types.actions.RECALL_PARTICIPATION, {
            eventId: this.event.id,
            participantId: this.event.participants.find((p) => p.userId === this.user.id).id,
        });
        this.$notify({
            type: "success",
            title: "Ваша участие в событии было успешно отозванно",
        });
        this.onEventUpdate();
    }
    onDeclareOwn() {
        if (!this.user.profile) {
            this.dialog.fillProfile = !this.dialog.fillProfile;
            return;
        }
        this.toggleOwnParticipation();
    }
    onRecallOwn() {
        this.dialog.recallOwnParticipation = !this.dialog.recallOwnParticipation;
    }
    toggleOwnParticipation() {
        this.dialog.declareOwnParticipation = !this.dialog.declareOwnParticipation;
    }
    async mounted() {
        const eventId = parseInt(this.$route.params.id, 10);
        this.event = await this.$store.dispatch(EventModule.types.actions.LOAD_EVENT, { id: eventId });
        this.category.items = () => this.eventCategories;
    }
};
EventPage = __decorate([
    Component({
        components: {
            UsersTable,
            ApproveDialog,
            EventDialog,
            EventParticipants,
            EventOwnParticipation,
        },
    })
], EventPage);
export default EventPage;
