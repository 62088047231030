import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import moment from "moment-timezone";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import { mask } from "vue-the-mask";
import Validation from "@/helpers/Validation";
import { VueEditor } from "vue2-editor";
import EventModule from "@/store/module/shared/EventModule";
import AdminModule from "@/store/module/admin/AdminModule";
import HandbookModule from "@/store/module/shared/HandbookModule";
import ApproveDialog from "../Approve.vue";
import UserModule from "@/store/module/user/UserModule";
let EventDialog = class EventDialog extends Vue {
    constructor() {
        super(...arguments);
        this.pickedRegion = null;
        this.pickedRegionalStatus = null;
        this.approve = {
            add: false,
            update: false,
        };
        this.name = {
            ref: "name",
            type: "input",
            label: "Название",
            value: "",
            placeholder: "Кубок России",
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        this.categories = {
            ref: "categories",
            type: "select",
            label: "Категории",
            value: [],
            items: () => this.eventCategories,
            onInput: () => {
                this.renderLimitsInputs();
            },
            isMultiple: true,
            noData: "Список доступных категорий пуст",
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        this.regionalStatus = {
            ref: "regionalStatus",
            type: "select",
            label: "Региональный статус",
            value: "",
            items: () => this.eventRegionalStatuses,
            onInput: (id) => {
                this.pickedRegionalStatus = id;
            },
            isMultiple: false,
            noData: "Список доступных региональных статусов пуст",
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        this.eventType = {
            ref: "eventType",
            type: "select",
            label: "Тип",
            value: "",
            items: () => this.eventTypes,
            onInput: () => { },
            isMultiple: false,
            noData: "Список типов для выбранного регионального статуса пуст",
            showOnlyForSuperAuth: false,
            rules: [],
        };
        this.region = {
            ref: "region",
            type: "select",
            label: "Регион",
            value: "",
            items: () => this.regions,
            onInput: (id) => {
                this.pickedRegion = id;
                this.city.disabled = false;
            },
            isMultiple: false,
            disabled: false,
            noData: "Список доступных регионов пуст",
            showOnlyForSuperAuth: true,
            rules: [],
        };
        this.city = {
            ref: "city",
            type: "select",
            label: "Город",
            value: "",
            items: () => this.citiesByRegion,
            onInput: () => { },
            isMultiple: false,
            noData: "Список городов для выбранного региона пуст",
            disabled: true,
            showOnlyForSuperAuth: false,
            rules: [],
        };
        this.startDate = {
            ref: "startDate",
            type: "datepicker",
            label: "Дата начала",
            value: "",
            isOpened: false,
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        this.endDate = {
            ref: "endDate",
            type: "datepicker",
            label: "Дата окончания",
            value: "",
            isOpened: false,
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        this.registrationEndDate = {
            ref: "registrationEndDate",
            type: "datepicker",
            label: "Дата конца регистрации",
            value: "",
            isOpened: false,
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        this.isOpenForRegistration = {
            type: "checkbox",
            label: "Регистрация открыта",
            value: false,
            onChange: () => { },
            showOnlyForSuperAuth: false,
        };
        this.isPrivate = {
            type: "checkbox",
            label: "Является закрытым",
            value: false,
            onChange: () => { },
            showOnlyForSuperAuth: false,
        };
        this.hasParticipationFee = {
            type: "checkbox",
            label: "Взнос за участие",
            value: false,
            onChange: () => {
                this.participationFee.show = this.hasParticipationFee.value === true ? true : false;
            },
            showOnlyForSuperAuth: false,
        };
        this.participationFee = {
            ref: "participationFee",
            type: "input",
            label: "Размер членского взноса (руб)",
            value: "",
            placeholder: "500",
            showOnlyForSuperAuth: false,
            show: false,
            rules: [],
        };
        this.disciplines = {
            type: "treeview",
            items: () => this.eventDisciplinesGroups.map((item) => {
                return {
                    ...item,
                    children: this.eventDisciplines
                        .filter((childItem) => childItem.groupId === item.id)
                        .map((childItem) => ({
                        ...childItem,
                        id: childItem.id + 10000,
                    })),
                };
            }),
            value: [],
            onInput: () => {
                this.renderLimitsInputs();
            },
        };
        this.limits = [];
        this.logo = {
            ref: "logo",
            type: "fileupload",
            icon: "mdi-image-filter-black-white",
            placeholder: "Загрузите логотип",
            value: "",
            accept: "image/*",
            onChange: async (file) => {
                if (file === null)
                    return;
                const fd = new FormData();
                fd.append("image", file);
                const response = await this.$store.dispatch(EventModule.types.actions.UPLOAD_AVATAR, { fd });
                this.logo.value = response.filename;
            },
            rules: [],
            showOnlyForSuperAuth: false,
        };
        this.description = {
            type: "wysiwyg",
            value: "",
            showOnlyForSuperAuth: false,
        };
        this.baseFields = [
            this.name,
            this.regionalStatus,
            this.eventType,
            this.categories,
            this.isOpenForRegistration,
            this.isPrivate,
        ];
        this.geoFields = [this.region, this.city];
        this.timeFields = [this.startDate, this.endDate, this.registrationEndDate];
        this.feeFields = [this.hasParticipationFee, this.participationFee];
    }
    get showDialog() {
        return this.value;
    }
    set showDialog(value) {
        this.$emit("input", value);
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    get isCoach() {
        return this.$store.getters[UserModule.types.getters.iS_COACH];
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get isRegionalAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_REGIONAL_ADMIN];
    }
    get eventDisciplines() {
        return this.$store.getters[HandbookModule.types.getters.EVENT_DISCIPLINES];
    }
    get eventDisciplinesTable() {
        const table = new Map();
        this.eventDisciplines.map((d) => table.set(d.id, d));
        return table;
    }
    get eventDisciplinesGroups() {
        return this.$store.getters[HandbookModule.types.getters.EVENT_DISCIPLINES_GROUPS];
    }
    get eventDisciplinesGroupsTable() {
        const table = new Map();
        this.eventDisciplinesGroups.map((g) => table.set(g.id, g));
        return table;
    }
    get eventTypes() {
        if (this.pickedRegionalStatus === null)
            return [];
        return this.$store.getters[HandbookModule.types.getters.EVENT_TYPES].filter((item) => item.regionalStatusId === this.pickedRegionalStatus);
    }
    get eventRegionalStatuses() {
        if (this.isSuperAdmin)
            return this.$store.getters[HandbookModule.types.getters.EVENT_REGIONAL_STATUSES];
        return this.$store.getters[HandbookModule.types.getters.EVENT_REGIONAL_STATUSES].filter((item) => item.id === 3 || item.id === 4);
    }
    get eventCategoriesHandbook() {
        return this.$store.getters[HandbookModule.types.getters.EVENT_CATEGORIES];
    }
    get eventCategoriesHandbookTable() {
        const table = new Map();
        for (const item of this.eventCategoriesHandbook) {
            table.set(item.id, item);
        }
        return table;
    }
    get eventCategories() {
        return this.eventCategoriesHandbook.map((item) => ({
            id: item.id,
            name: `${item.name} от ${item.ageCategory.ageFrom} до ${item.ageCategory.ageTo} лет`,
        }));
    }
    get citiesByRegion() {
        if (this.pickedRegion === null)
            return [];
        return this.$store.getters[HandbookModule.types.getters.CITIES].filter((item) => item.regionId === this.pickedRegion);
    }
    get regions() {
        return this.$store.getters[HandbookModule.types.getters.REGIONS];
    }
    get defaultAvatar() {
        return `${IMAGE_SERVER_URL}/public/archery-event-placeholder.jpg`;
    }
    get uploadedAvatar() {
        return `${IMAGE_SERVER_URL}/public/${this.logo.value}`;
    }
    getDisciplines() {
        const result = [];
        for (const value of this.disciplines.value) {
            const isGroup = !Object.prototype.hasOwnProperty.call(value, "groupId");
            if (isGroup) {
                const group = this.eventDisciplinesGroupsTable.get(value.id);
                result.push({
                    disciplineGroupId: group.id,
                    disciplineId: null,
                    disciplineGroup: group,
                    discipline: null,
                });
            }
            else {
                const group = this.eventDisciplinesGroupsTable.get(value.groupId);
                const discipline = this.eventDisciplinesTable.get(value.id - 10000);
                result.push({
                    disciplineGroupId: group.id,
                    disciplineId: discipline.id,
                    disciplineGroup: group,
                    discipline: discipline,
                });
            }
        }
        return result;
    }
    renderLimitsInputs() {
        const result = [];
        const pickedCategories = [];
        for (const categoryId of this.categories.value) {
            pickedCategories.push(this.eventCategoriesHandbookTable.get(categoryId));
        }
        const disciplines = this.getDisciplines();
        if (pickedCategories.length === 0 || disciplines.length === 0) {
            this.limits.splice(0);
            return result;
        }
        const currentInputsTable = new Map();
        for (const field of this.limits) {
            currentInputsTable.set(field.input.ref, field);
        }
        for (const category of pickedCategories) {
            for (const discipline of disciplines) {
                const key = `${category.id}-${discipline.disciplineGroupId}-${discipline.disciplineId}`;
                const disciplineName = discipline.discipline
                    ? `[${discipline.disciplineGroup.name} ${discipline.discipline.name}]`
                    : `[${discipline.disciplineGroup.name}]`;
                let inputValue = "";
                let checkboxValue = true;
                const currentInputValue = currentInputsTable.get(key);
                const eventValue = this.event
                    ? this.event.limits.find((l) => l.categoryId === category.id &&
                        l.disciplineGroupId === discipline.disciplineGroupId &&
                        l.disciplineId === l.disciplineId)
                    : undefined;
                if (currentInputValue) {
                    inputValue = currentInputValue.input.value;
                    checkboxValue = currentInputValue.checkbox.value;
                }
                else if (eventValue) {
                    inputValue = eventValue.limit.toString();
                    if (parseInt(inputValue) === this.$store.getters[EventModule.types.getters.UNLIMITED_PARTICIPANTS_VALUE]) {
                        checkboxValue = true;
                    }
                    else {
                        checkboxValue = false;
                    }
                }
                const input = {
                    ref: key,
                    type: "input",
                    label: `${disciplineName} - ${category.name} от ${category.ageCategory.ageFrom} до ${category.ageCategory.ageTo}`,
                    value: inputValue,
                    placeholder: "100",
                    showOnlyForSuperAuth: false,
                    disabled: true,
                    data: {
                        category,
                        discipline,
                    },
                };
                const checkbox = {
                    ref: `${key}-checkbox`,
                    type: "checkbox",
                    label: "Неограниченное кол-во",
                    value: checkboxValue,
                    showOnlyForSuperAuth: false,
                    onChange: () => {
                        if (checkbox.value) {
                            input.disabled = true;
                            input.value = "";
                        }
                        else {
                            input.disabled = false;
                        }
                    },
                };
                checkbox.onChange();
                result.push({ input, checkbox });
            }
        }
        this.limits.splice(0);
        result.map((r) => this.limits.push(r));
    }
    async add() {
        const form = this.baseFields.concat(this.geoFields, this.timeFields, this.feeFields);
        let hasError = Validation.validateWithView(form.filter((item) => Object.prototype.hasOwnProperty.call(item, "ref")), this.$refs);
        if (hasError)
            return;
        let creatorId = null;
        let userCreatorId = null;
        if (this.isSuperAdmin || this.isRegionalAdmin) {
            creatorId = this.admin.id;
        }
        else {
            userCreatorId = this.user.id;
        }
        const event = {
            name: this.name.value,
            categories: this.$store.getters[HandbookModule.types.getters.EVENT_CATEGORIES]
                .filter((item) => this.categories.value.indexOf(item.id) !== -1)
                .map((item) => ({ categoryId: item.id })),
            typeId: this.$store.getters[HandbookModule.types.getters.EVENT_TYPES].find((item) => item.id === this.eventType.value).id,
            address: this.region.value !== ""
                ? {
                    regionId: this.region.value === "" ? null : this.region.value,
                    cityId: this.city.value === "" ? null : this.city.value,
                }
                : null,
            disciplines: this.getDisciplines().map((d) => ({
                disciplineGroupId: d.disciplineGroupId,
                disciplineId: d.disciplineId,
            })),
            startDate: this.startDate.value,
            endDate: this.endDate.value,
            registrationEndDate: this.registrationEndDate.value,
            isOpenForRegistration: this.isOpenForRegistration.value,
            participationFee: this.participationFee.value === "" ? null : parseFloat(this.participationFee.value),
            logo: this.logo.value === "" ? "archery-event-placeholder.jpg" : this.logo.value,
            description: this.description.value,
            isPremoderated: true,
            creatorId,
            userCreatorId,
            isPrivate: this.isPrivate.value,
            limits: this.limits.map((f) => ({
                categoryId: f.input.data.category.id,
                disciplineGroupId: f.input.data.discipline.disciplineGroupId,
                disciplineId: f.input.data.discipline.disciplineId,
                limit: f.checkbox.value
                    ? this.$store.getters[EventModule.types.getters.UNLIMITED_PARTICIPANTS_VALUE]
                    : f.input.value,
            })),
        };
        const existedEvent = this.$store.getters[EventModule.types.getters.EVENTS].find((item) => item.name === event.name &&
            moment(item.startDate).tz("Europe/Moscow").format("YYYY-MM-DD") ===
                moment(event.startDate).tz("Europe/Moscow").format("YYYY-MM-DD"));
        if (existedEvent !== undefined) {
            return this.$notify({
                type: "error",
                duration: 3000,
                title: "Уже есть событие с таким названием и датой начала",
            });
        }
        await this.$store.dispatch(EventModule.types.actions.ADD_EVENT, { event });
        this.$notify({
            type: "success",
            title: "Событие успешно добавлено и отправлено на премодерацию",
        });
    }
    async update() {
        const form = this.baseFields.concat(this.geoFields, this.timeFields, this.feeFields);
        let hasError = Validation.validateWithView(form.filter((item) => Object.prototype.hasOwnProperty.call(item, "ref")), this.$refs);
        if (hasError)
            return;
        const event = {
            id: this.event.id,
            name: this.name.value,
            categories: this.categories.value
                .map((item) => ({
                eventId: this.event.id,
                categoryId: item,
            }))
                .map((item) => {
                const already = this.event.categories.find((category) => category.categoryId === item.categoryId);
                if (already !== undefined) {
                    item.id = already.id;
                }
                return item;
            }),
            typeId: parseInt(this.eventType.value),
            disciplines: this.getDisciplines().map((item) => {
                const already = this.event.disciplines.find((discipline) => discipline.disciplineId === item.disciplineId && discipline.disciplineGroupId === item.disciplineGroupId);
                if (already !== undefined) {
                    item.id = already.id;
                }
                return {
                    id: already !== undefined ? already.id : undefined,
                    eventId: this.event.id,
                    disciplineGroupId: item.disciplineGroupId,
                    disciplineId: item.disciplineId,
                };
            }),
            startDate: this.startDate.value,
            endDate: this.endDate.value,
            registrationEndDate: this.registrationEndDate.value,
            isOpenForRegistration: this.isOpenForRegistration.value,
            participationFee: this.participationFee.value === "" ? null : parseFloat(this.participationFee.value),
            logo: this.logo.value,
            description: this.description.value,
            isPremoderated: this.event.isPremoderated,
            creatorId: this.event.creatorId,
            userCreatorId: this.event.userCreatorId,
            isPrivate: this.isPrivate.value,
            limits: this.limits.map((f) => ({
                categoryId: f.input.data.category.id,
                disciplineGroupId: f.input.data.discipline.disciplineGroupId,
                disciplineId: f.input.data.discipline.disciplineId,
                limit: f.checkbox.value
                    ? this.$store.getters[EventModule.types.getters.UNLIMITED_PARTICIPANTS_VALUE]
                    : f.input.value,
            })),
        };
        const noAddress = this.event.addressId === null && this.region.value !== "" && this.city.value !== "";
        const equalAddress = this.event.addressId !== null &&
            parseInt(this.region.value) === this.event.address.regionId &&
            parseInt(this.city.value) === this.event.address.cityId;
        if (noAddress || (!equalAddress && this.region.value && this.city.value)) {
            const response = await this.$store.dispatch(HandbookModule.types.actions.ADD_ADDRESS, {
                address: {
                    regionId: parseInt(this.region.value),
                    cityId: parseInt(this.city.value),
                },
            });
            event.addressId = response.id;
        }
        await this.$store.dispatch(EventModule.types.actions.UPDATE_EVENT, { eventId: this.event.id, event });
        this.$notify({
            type: "success",
            title: "Данные события успешно обновлены",
        });
        this.$emit("update");
    }
    setTestFormData() {
        this.logo.value = "1615156271937_duck.png";
        this.name.value = "test";
        this.regionalStatus.value = 1;
        this.regionalStatus.onInput(1);
        this.eventType.value = 3;
        this.categories.value = [1, 2];
        // this.region.value = 1
        // this.region.onInput(1)
        // this.city.value = 1
        this.startDate.value = moment().format("YYYY-MM-DD");
        this.endDate.value = moment().format("YYYY-MM-DD");
        this.registrationEndDate.value = moment().format("YYYY-MM-DD");
        this.isOpenForRegistration.value = true;
        this.hasParticipationFee.value = true;
        this.isPrivate.value = false;
        this.hasParticipationFee.onChange();
        this.participationFee.value = "500";
        this.description.value = "<b>Hello</b>";
        this.disciplines.value = [
            { id: 1, name: "Классический лук", code: "R" },
            { id: 10004, groupId: 5, name: "Длинный лук", code: "L" },
        ];
        this.renderLimitsInputs();
    }
    async mounted() {
        await this.$store.dispatch(HandbookModule.types.actions.WAIT_FOR_HANDBOOK_LOAD);
        //this.setTestFormData()
        if (this.isUpdate) {
            this.logo.value = this.event.logo;
            this.name.value = this.event.name;
            this.regionalStatus.value = this.event.type.regionalStatusId;
            this.regionalStatus.onInput(this.event.type.regionalStatusId);
            this.eventType.value = this.event.type.id;
            this.categories.value = this.event.categories.map((item) => item.categoryId);
            if (this.event.address !== null) {
                this.region.value = this.event.address.region.id;
                this.region.onInput(this.event.address.region.id);
                if (this.event.address.city !== null) {
                    this.city.value = this.event.address.city.id;
                }
            }
            this.startDate.value = moment(this.event.startDate).tz("Europe/Moscow").format("YYYY-MM-DD");
            this.endDate.value = moment(this.event.endDate).tz("Europe/Moscow").format("YYYY-MM-DD");
            this.registrationEndDate.value = moment(this.event.registrationEndDate).tz("Europe/Moscow").format("YYYY-MM-DD");
            this.isOpenForRegistration.value = this.event.isOpenForRegistration;
            this.hasParticipationFee.value = this.event.participationFee === null ? false : true;
            this.isPrivate.value = this.event.isPrivate;
            this.hasParticipationFee.onChange();
            this.participationFee.value = this.event.participationFee === null ? "" : String(this.event.participationFee);
            this.description.value = this.event.description;
            this.disciplines.value = this.event.disciplines.map((item) => item.discipline !== null && item.discipline.groupId !== undefined
                ? { ...item.discipline, id: item.discipline.id + 10000 }
                : item.disciplineGroup);
            this.renderLimitsInputs();
        }
        // if (!this.isUpdate) {
        //   this.setTestFormData()
        // }
        if (this.isRegionalAdmin) {
            this.region.value = this.admin.regionId;
            this.region.onInput(this.admin.regionId);
            this.region.disabled = true;
        }
        else if (this.isCoach) {
            this.region.value = this.user.address.regionId;
            this.region.onInput(this.user.address.regionId);
            this.region.disabled = true;
        }
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], EventDialog.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean })
], EventDialog.prototype, "isUpdate", void 0);
__decorate([
    Prop({ type: Object })
], EventDialog.prototype, "event", void 0);
EventDialog = __decorate([
    Component({
        components: {
            ApproveDialog,
            VueEditor,
        },
        directives: {
            mask,
        },
    })
], EventDialog);
export default EventDialog;
